body {

}
body.slideshow-open {
  position:fixed;
  overflow:hidden;
}
main {
  padding:10px 0px 0;
  margin: 0 auto;
  width: 70%;
}

/* header */
header {
  padding:5px 0;
  background: #faf4f0;
  border-bottom: 1px solid #999;
}

nav.main-navigation {
  width: 70%;
  margin:0 auto;
}

nav.main-navigation a, nav.main-navigation a:visited {
  color: #666;
  text-decoration: none;
  display:inline-block;
  padding:5px 8px;
}

@media (max-width: 1400px) {
  main, nav.main-navigation, .btn-selection-container-fixed {
    width:80%;
  }
}
@media (max-width: 1250px) {
  main, nav.main-navigation, .btn-selection-container-fixed {
    width:90%;
  }
}
@media (max-width: 1000px) {
  main, nav.main-navigation, .btn-selection-container-fixed {
    width:98%;
  }
}

.message {
  padding:5px 10px;
  max-width:500px;
  margin: 0 auto;

}
.message.error {
  background-color: #ffe4e0;
  border: 1px solid #bd2815;
}

.main-content h1 {
  margin:10px 0;
  font-size: 25px;
}
/* create album form */

.frm-edit-album {
  margin: 0 auto;
}
.frm-edit-album a, .frm-edit-album a:visited {
  text-decoration: none;
  color: blue;
}
.frm-item {
  padding: 5px 0;
  line-height: 1.3;
  display: inline-block;
}
.frm-item.half {
  width: 50%;
}
.frm-item.whole {
  width:100%;
}
.frm-item input[type=text], .frm-item input[type=datetime-local], .frm-item input[type=email] {
  padding:3px 5px;
  width:80%;
}
.frm-item textarea {
  padding:3px 5px;
  width:90%;
}
.frm-item select {
  padding:3px 5px;
  width:80%;
}
.frm-section-header {
  font-size: 19px;
  margin:15px  0 5px;
}
.frm-album-user-row {
  display: flex;
}

.frm-album-user-row > div, .frm-album-size-row > div {
  flex: 1;
}
.frm-album-user-row > div.user-role-container {
  flex: .6;
}
.sizes-crop-container {
  min-width: 400px;
}
.frm-album-size-row {
  display:flex;
}
.btn-container {
  padding:10px 0 0;
}
.btn {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:12px;
	font-weight:bold;
	padding: 5px 14px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.btn:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.btn:active {
	position:relative;
	top:1px;
}

/* gallery*/
.gallery-items {
  display:flex;
  flex-wrap: wrap;
}
.gallery-items a, .gallery-items a:visited {
  color: blue;
  text-decoration: none;
  font-size: 18px;
}
.gallery-album-item {
  display:inline-block;
  padding:10px 5px;
  text-align:center;
  min-width:210px;
  flex:1;
  box-sizing: border-box;

}
.gallery-album-item img {
  max-width:100%;
}
/** album **/
.album-page h1 {
  text-align:center;
}
.album-page .album-text {
  max-width:90%;
  text-align: left;
  margin: 0 auto 10px;
  border: 3px double #cf2e06;
  padding: 0 10px;
  box-sizing: border-box;
}
.image-checkbox-container {
  text-align: left;
  line-height: 1;
}
.album-metadata {
  text-align:center;
  padding:0 0 5px;
}

.album-metadata span {
  display:inline-block;
  padding:0 10px;
}
.tabs-container {
  padding:5px 0;
  text-align:center;
}
.tab-link {
  display:inline-block;
  padding: 5px 10px;
  border:1px solid black;
  cursor: pointer;
  border-radius: 5px;
  margin:0 5px;
}
.tab-link.active-tab {
  border:1px solid blue;
}
.images-tab-section {

}
.image-thumbnail-container {
  display: flex;
  flex-wrap: wrap;
}
.thumbnail-image-container {
  display:inline-block;
  position:relative;
}
.image-title {
  background-color:rgba(0, 0, 0, 0.6);
  color: #fff;
  position:absolute;
  padding:3px 15px;
  border-radius:0 0 5px 0;
  font-size: 16px;
  text-align:center;
  pointer-events: none;

}
.image-thumbnail {
  padding: 3px 5px 5px 5px;
  text-align: center;
  width: 20%;
  box-sizing: border-box;
  border: 2px solid transparent;
  position:relative;
}
.mobile-only {
  display: none;
}

.image-thumbnail img {
  max-width: 100%;
  height: auto;
}
.btn-selection-container-fixed {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 100;
  display: none;
  margin: 0 auto;
}
.btn-selection-container-fixed input.disabled {
  display:none;
}
.btn-selection-container, .btn-selection-container-fixed {
  padding:5px 0;
  text-align: center;
}
.btn-selection-container div, .btn-selection-container-fixed div {
  padding: 5px 0;
}
.btn-selection-container input, .btn-selection-container-fixed input {
  margin: 0 5px;
}
@media (max-width: 900px) {
  .image-thumbnail {
    width: 25%;
  }
}
@media (max-width:700px) {
  .image-title {
    font-size:12px;
    padding:3px 5px;

  }
  .image-thumbnail {
    width: 33.33%;
  }
}
@media (max-width:530px) {

  .image-thumbnail {
    width: 50%;
  }
}
@media (max-width:450px) {
  .image-title {
    font-size:16px;
    padding:3px 15px;
    background-color:rgba(0, 0, 0, 0.4);
    border-radius: 0;
    left: 0;
    right: 0;
  }
  .thumbnail-image-container {
    display:block;
  }
  .image-thumbnail {
    width:100%;
    padding: 3px 0 10px 0;
    text-align:center;
    border: 0;
  }
  .image-thumbnail img {
    width:100%;
  }
  .mobile-only {
    display:initial;
  }
}

.image-thumbnail.selected-image {
  background: #d9e8fc;
  opacity: .8;
  border:5px solid #d9e8fc;
  /* border: 1px solid gold; */
}
.image-thumbnail img {
  cursor: pointer;
}
.drag-files-container {
  width: 100%;
  padding:50px 0;
  text-align:center;
  font-size: 28px;
  border: 5px dashed black;
}

.upload-image-thumbnail img {
  max-height:100%;
  max-width: 100%;
}
.upload-image-thumbnail {
  width: 150px;
  padding:5px 3px;
  text-align:center;
}
.users-tab-section table {
  width: 100%
}

/* slideshow */
.slideshow-overlay {
  position:fixed;
  width:100%;
  height: 100%;
  left:0;
  top:0;
  bottom:0;
  right:0;
  z-index: 100;
  background:#ccc;
}
.slideshow-container {
  height: 75%;
  box-sizing: border-box;
}
.large-image-panel {
  background:#fff;
  padding:10px;
  box-sizing: border-box;
  height:10%;
}
.image-panel-top {
  text-align:center;
}
.image-panel-top .btn {
  margin:0 10px;
}
.large-image {
  padding:0 10px;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.large-image img {
  max-width: 100%;
  max-height: 100%;
}
.slideshow-carousel {
  height:25%;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
}
.slideshow-carousel-inner-container {
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.slideshow-carousel-thumbnail {
  display:inline-block;
  padding:5px;
  text-align:center;
  font-size: 15px;
}

.slideshow-carousel-thumbnail.selected-image {
  background: #d9e8fc;
  opacity: .5;
}
.slideshow-carousel-thumbnail img {
  border: transparent 2px solid;
  max-height:133px;
  max-width:200px;
  width: initial;
  height: initial;
}
.carousel-active-img img {
  border:blue 2px solid;
}
.large-image-scroll-container {
  overflow:hidden;
  height: 90%;
  width:100%;
}
.large-image-scroll {
  height:100%;
  width: 300%;
  display: flex;
  position:relative;
  left: -100%;
}
.large-image-scroll > div {
  width:100%;
}
input.disabled {
  cursor: not-allowed;
  pointer-events: none;
  visibility: hidden;
}
@media (max-height: 580px) {
  .slideshow-carousel-inner-container {
    display: none;
  }
}
@media (max-height: 800px) {
  .slideshow-container {
    height:80%
  }
  .slideshow-carousel {
    height:20%
  }
  .slideshow-carousel-thumbnail img {
    max-height:100px;
    width: auto;
    height: auto;
  }
}
@media (max-height:700px) {
  .slideshow-carousel-thumbnail img {
    max-height: 90px;
  }
}
@media (max-width: 450px) {
  .large-image-scroll-container {
    height: 65%
  }
  .button-wrapper {
    background: transparent;
    display:block;
    width: 100%;
    height: 50%;
    z-index: 100;
    position:absolute;
    bottom:0;
  }
  .mobile-close {
    float:right;
    height:100%;
    width: 40px;
    position:relative;
    background:#ddd;
  }
  .mobile-close:after, .mobile-close:before {
    position:absolute;
    content: "";
    display:block;
    width: 70%;
    background: #000;
    height: 3px;
    left:50%;
    top:50%;

  }
  .mobile-close:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .mobile-close:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .slideshow-container {
    height: 100%;
  }
  .mobile-options-container {
    height:5%;
    background: #fff;
  }
  .large-image {
    height:100%;
    padding:0;
    overflow: hidden;
    position: relative;
  }

  .large-image-panel {
    height:30%;
  }

  .slideshow-carousel {
    display: none;
  }
  .image-filename {
    display: block;
  }
  .image-panel-top .btn {
    display: block;
    margin: 10px auto;
  }
  .mobile-full-size {
    display: flex;
    flex-direction: column;
  }
  .mobile-full-size .large-image.current-image {
    overflow: scroll;
    display:block;
  }
  .mobile-full-size .large-image.current-image img {
    max-width: initial;
    max-height: initial;
  }
  .mobile-full-size .large-image-scroll-container {
    height:88%
  }
  .mobile-full-size .large-image-panel {
    height: 7%;
    min-height: 50px;
  }
  .mobile-full-size .large-image-panel input {
    display:none;
  }
}